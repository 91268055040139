import { Search, SentimentDissatisfied } from "@mui/icons-material";
import {
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Button,
  ButtonGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { config } from "../App";
import Footer from "./Footer";
import Header from "./Header";
import ProductCard from "./ProductCard";
import Cart from "./Cart";
import { generateCartItemsFrom } from "./Cart";
import "./Products.css";

const Products = () => {
  const [productDetails, setProductDetails] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [categories, setCategories] = useState([]); // Para manejar las categorías
  const [debounceTime, setDebounceTime] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [cartItem, setCartItem] = useState([]);
  const [cartLoad, setcartLoad] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  let token = localStorage.getItem("token");

  const performAPICall = async () => {
    setIsLoading(true);
    try {
      let response = await axios.get(`${config.endpoint}/products`);
      setProductDetails(response.data);
      setFilteredProduct(response.data);
      setcartLoad(true);

      // Extraer categorías únicas de los productos
      const uniqueCategories = [...new Set(response.data.map(product => product.category))];
      setCategories(uniqueCategories);

    } catch (error) {
      if (error.response && error.response.status === 400) {
        enqueueSnackbar(error.response.data.message, { variant: "error" });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    performAPICall();
  }, []);

  useEffect(() => {
    fetchCart(token);
  }, [cartLoad]);

  const performSearch = async (text) => {
    setIsLoading(true);
    try {
      let response = await axios.get(
        `${config.endpoint}/products/search?value=${text}`
      );
      setFilteredProduct(response.data);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          setFilteredProduct([]);
        }
        if (error.response.status === 500) {
          enqueueSnackbar(error.response.data.message, { variant: "error" });
          setFilteredProduct(productDetails);
        }
      } else {
        enqueueSnackbar(
          "Algo salió mal. Compruebe que el backend se esté ejecutando, que sea accesible y que devuelva un JSON válido.",
          { variant: "error" }
        );
      }
    }
    setIsLoading(false);
  };

  const debounceSearch = (event, debounceTimeId) => {
    var text = event.target.value;
    if (debounceTimeId) {
      clearTimeout(debounceTimeId);
    }
    const newTimeOut = setTimeout(() => {
      performSearch(text);
    }, 500);
    setDebounceTime(newTimeOut);
  };

  const fetchCart = async (token) => {
    if (!token) return;
    try {
      let response = await axios.get(`${config.endpoint}/cart`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setCartItem(generateCartItemsFrom(response.data, productDetails));
      }
    } catch (e) {
      if (e.response && e.response.status === 400) {
        enqueueSnackbar(e.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar(
          "No se pudieron recuperar los detalles del carrito. Compruebe que el backend se esté ejecutando, que sea accesible y que devuelva un JSON válido.",
          {
            variant: "error",
          }
        );
      }
      return null;
    }
  };

  const isItemInCart = (items, productId) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].productId === productId) {
        return true;
      }
    }
    return false;
  };

  const addToCart = async (
    token,
    items,
    products,
    productId,
    qty = 1,
    options = { preventDuplicate: false }
  ) => {
    if (token) {
      if (isItemInCart(items, productId)) {
        enqueueSnackbar(
          "Artículo ya en el carrito. Utilice la barra lateral del carrito para actualizar la cantidad o eliminar el artículo.",
          {
            variant: "warning",
          }
        );
      } else {
        addInCart(productId, qty);
      }
    } else {
      enqueueSnackbar("Inicie sesión para agregar un artículo al carrito", {
        variant: "error",
      });
    }
  };

  const addInCart = async (productId, qty) => {
    try {
      let response = await axios.post(
        `${config.endpoint}/cart`,
        {
          productId: productId,
          qty: qty,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCartItem(generateCartItemsFrom(response.data, productDetails));
    } catch (e) {
      if (e.response && e.response.status === 400) {
        enqueueSnackbar(e.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar(
          "No se pudieron recuperar los detalles del carrito. Compruebe que el backend se esté ejecutando, que sea accesible y que devuelva un JSON válido.",
          {
            variant: "error",
          }
        );
      }
      return null;
    }
  };

  let handleCart = (productId) => {
    addToCart(token, cartItem, productDetails, productId);
  };

  const handleQuantity = (productId, qty) => {
    addInCart(productId, qty);
  };

  // Nueva función para manejar el filtrado por categoría
  const handleFilter = (category) => {
    if (category === "All") {
      setFilteredProduct(productDetails);
    } else {
      const filtered = productDetails.filter(product => product.category === category);
      setFilteredProduct(filtered);
    }
  };

  return (
    <div>
      <Header>
        <TextField
          className="search-desktop"
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search color="primary" />
              </InputAdornment>
            ),
          }}
          placeholder="Search for items/categories"
          name="search"
          onChange={(e) => debounceSearch(e, debounceTime)}
        />
      </Header>
      <TextField
        className="search-mobile"
        size="large"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search color="primary" />
            </InputAdornment>
          ),
        }}
        placeholder="Search for items/categories"
        name="search"
        onChange={(e) => debounceSearch(e, debounceTime)}
      />

      <Grid container>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          xs
          md
        >
          <Grid item className="product-grid">
            <Box className="hero">
              <p className="hero-heading">
                Tu regalo <span className="hero-highlight">a un click</span>
              </p>
            </Box>
          </Grid>

          {/* Botones de filtro */}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" my={2}>
              <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Button onClick={() => handleFilter("All")}>Todos</Button>
                {categories.map((category) => (
                  <Button key={category} onClick={() => handleFilter(category)}>
                    {category}
                  </Button>
                ))}
              </ButtonGroup>
            </Box>
          </Grid>

          {isLoading ? (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ margin: "auto" }}
              py={10}
            >
              <CircularProgress size={30} />
              <h4> Cargando Productos... </h4>
            </Box>
          ) : (
            <Grid
              container
              item
              spacing={6}
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              mr={1}
              marginRight="50px"
              marginLeft="5px"
              my={3}
            >
              {filteredProduct.length ? (
                filteredProduct.map((product) => (
                  <Grid item key={product["_id"]} xs={12} sm={6} md={4} lg={3}>
                    <ProductCard
                      product={product}
                      handleAddToCart={(event) => handleCart(product["_id"])}
                    />
                  </Grid>
                ))
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  py={10}
                  sx={{ margin: "auto" }}
                >
                  <SentimentDissatisfied size={40} />
                  <h4>Productos no encontrados</h4>
                </Box>
              )}
            </Grid>
          )}
        </Grid>
        {token && (
          <Grid
            container
            item
            xs={12}
            md={3}
            style={{ backgroundColor: "#E9F5E1", height: "100vh" }}
            justifyContent="center"
            alignItems="stretch"
          >
            <Cart
              products={productDetails}
              items={cartItem}
              handleQuantity={handleQuantity}
            />
          </Grid>
        )}
      </Grid>

      <Footer />
    </div>
  );
};

export default Products;